'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = CharacterList;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _CharacterDetails = require('./CharacterDetails');

var _CharacterDetails2 = _interopRequireDefault(_CharacterDetails);

var _util = require('./util');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Renders the list of heroes and their sidekicks and/or nemesis
 */
function CharacterList(props) {
  try {
    var characters = props.characters;


    if (characters.length === 0) {
      return _react2.default.createElement(
        'ul',
        { className: 'unstyled character-list' },
        _react2.default.createElement(
          'li',
          { className: 'loading' },
          'Loading characters...'
        )
      );
    } else {
      var heroes = characters.filter(function (char) {
        return char.type === 'hero';
      });

      return _react2.default.createElement(
        'ul',
        { className: 'unstyled character-list' },
        heroes.map(function (hero) {
          return _react2.default.createElement(RelatedCharacters, _extends({ key: hero.links.self, hero: hero }, props));
        })
      );
    }
  } catch (error) {
    props.errorHandler(error);
    return null;
  }
}

/**
 * Renders a hero an its related sidekick and/or nemesis
 */
function RelatedCharacters(props) {
  var hero = props.hero,
      selectCharacter = props.selectCharacter,
      characters = props.characters;

  // Get the related characters, if any

  var sidekick = (0, _util.findByUrl)(characters, hero.links.sidekick);
  var nemesis = (0, _util.findByUrl)(characters, hero.links.nemesis);

  // Get the character's slug (e.g. "wonderwoman")
  var slug = (0, _util.getSlug)(hero.links.self);

  return _react2.default.createElement(
    'li',
    null,
    _react2.default.createElement(
      'ul',
      { className: 'unstyled related-characters' },
      _react2.default.createElement(
        'li',
        { className: 'character hero not-empty', onClick: function onClick() {
            return selectCharacter(hero);
          } },
        _react2.default.createElement('img', { className: 'avatar', src: 'img/avatars/' + slug + '.gif' }),
        _react2.default.createElement(
          'div',
          { className: 'info' },
          _react2.default.createElement(
            'div',
            { className: 'name' },
            hero.name
          ),
          _react2.default.createElement(
            'div',
            { className: 'powers' },
            hero.powers.length === 0 ? 'none' : hero.powers.length === 1 ? hero.powers[0] : hero.powers[0] + ', ...'
          ),
          _react2.default.createElement(
            'div',
            { className: 'weakness' },
            hero.weakness || 'none'
          )
        ),
        _react2.default.createElement(_CharacterDetails2.default, _extends({ character: hero }, props))
      ),
      _react2.default.createElement(RelatedCharacter, _extends({ type: 'sidekick', character: sidekick }, props)),
      _react2.default.createElement(RelatedCharacter, _extends({ type: 'nemesis', character: nemesis }, props))
    )
  );
}

/**
 * Renders a related character (sidekick or villain)
 */
function RelatedCharacter(props) {
  var type = props.type,
      character = props.character,
      selectCharacter = props.selectCharacter;

  // Use placeholder values if there is no related character

  var slug = character ? (0, _util.getSlug)(character.links.self) : 'anonymous';
  var clickHandler = character ? function () {
    return selectCharacter(character);
  } : function () {
    return true;
  };
  var name = character ? character.name : 'none';

  return _react2.default.createElement(
    'li',
    { className: 'character ' + type + ' ' + (character ? 'not-empty' : 'empty'), onClick: clickHandler },
    _react2.default.createElement('img', { className: 'avatar', src: 'img/avatars/' + slug + '.gif' }),
    _react2.default.createElement(
      'div',
      { className: 'name' },
      name
    ),
    character && _react2.default.createElement(_CharacterDetails2.default, _extends({ character: character }, props))
  );
}