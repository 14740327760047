'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CharacterDetails;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _util = require('./util');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Renders the detailed view of the currently-selected character
 */
function CharacterDetails(props) {
  try {
    var character = props.character;

    // Get the character's slug (e.g. "wonderwoman")

    var slug = (0, _util.getSlug)(character.links.self);

    return _react2.default.createElement(
      'article',
      { className: 'character-details' },
      _react2.default.createElement('img', { className: 'avatar', src: 'img/avatars/' + slug + '.gif' }),
      _react2.default.createElement(
        'div',
        { className: 'info' },
        _react2.default.createElement(
          'h1',
          { className: 'name' },
          character.name
        ),
        _react2.default.createElement(
          'ul',
          { className: 'powers' },
          character.powers.length === 0 ? _react2.default.createElement(
            'li',
            { className: 'power none' },
            'none'
          ) : character.powers.map(function (power) {
            return _react2.default.createElement(
              'li',
              { key: power, className: 'power' },
              power
            );
          })
        ),
        _react2.default.createElement(
          'ul',
          { className: 'weakness' },
          _react2.default.createElement(
            'li',
            { className: character.weakness ? '' : 'none' },
            character.weakness || 'none'
          )
        )
      ),
      _react2.default.createElement(
        'div',
        { className: 'bio' },
        character.bio || 'no bio :('
      )
    );
  } catch (error) {
    props.errorHandler(error);
    return null;
  }
}