'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _axios = require('axios');

var _axios2 = _interopRequireDefault(_axios);

var _util = require('./util');

var _CharacterList = require('./CharacterList');

var _CharacterList2 = _interopRequireDefault(_CharacterList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * The entire Super Tech Heroes app
 */
var App = function (_React$Component) {
  _inherits(App, _React$Component);

  function App(props) {
    _classCallCheck(this, App);

    // Create an Axios instance that's setup for the Super Tech Heroes API
    var _this = _possibleConstructorReturn(this, (App.__proto__ || Object.getPrototypeOf(App)).call(this, props));

    _this.request = _axios2.default.create({
      baseURL: (0, _util.getQueryParam)('API_ROOT', 'https://api.heroes.jamesmessinger.com'),
      headers: {
        'X-API-Key': (0, _util.getQueryParam)('API_KEY', 'DEMO')
      }
    });

    // Bind methods to this instance, so they can be called as standalone functions
    _this.selectCharacter = _this.selectCharacter.bind(_this);
    _this.errorHandler = _this.errorHandler.bind(_this);

    _this.state = {
      // The last error that occurred
      error: null,

      // The array of all characters
      characters: [],

      // The character that is currently selected for editing
      selectedCharacter: null,

      // Expose app methods as standalone functions
      request: _this.request,
      selectCharacter: _this.selectCharacter,
      errorHandler: _this.errorHandler
    };
    return _this;
  }

  /**
   * Immediately populates the full list of characters when the app is mounted
   */


  _createClass(App, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      this.request('/characters').then(function (response) {
        _this2.setState({ characters: response.data });
      }).catch(this.errorHandler);
    }

    /**
     * Renders the entire app
     */

  }, {
    key: 'render',
    value: function render() {
      return [_react2.default.createElement(ErrorMessage, { key: '1', error: this.state.error }), _react2.default.createElement(_CharacterList2.default, _extends({ key: '2' }, this.state))];
    }

    /**
     * Sets the `selectedCharacter` state
     *
     * @param {object} [selectedCharacter] - The selected character, or null to clear the selection
     */

  }, {
    key: 'selectCharacter',
    value: function selectCharacter(character) {
      if (character) {
        console.log(character.name + ' was selected');
      } else {
        console.log('No character is selected');
      }

      // this.setState({ selectedCharacter: character });
    }

    /**
     * Handles errors by setting the `error` state to the appropriate message
     *
     * @param {Error} error
     */

  }, {
    key: 'errorHandler',
    value: function errorHandler(error) {
      var errorCode = void 0,
          errorMessage = void 0;

      if (error.response && error.response.data && error.response.data.message) {
        errorCode = error.response.data.error;
        errorMessage = error.response.data.message;
      } else if (error.response && error.response) {
        errorCode = error.response.status;
        errorMessage = error.response.statusText;
      } else {
        errorCode = error.name || error.type || 'Error';
        errorMessage = error.message || 'Unknown error';
      }

      this.setState({ error: errorCode + ': ' + errorMessage });
    }
  }]);

  return App;
}(_react2.default.Component);

/**
 * Renders the error message
 *
 * @param {string} error - The error message to show
 */


exports.default = App;
function ErrorMessage(_ref) {
  var error = _ref.error;

  return error ? _react2.default.createElement(
    'div',
    { className: 'error-message' },
    error
  ) : null;
}